@import '~antd/dist/antd.css';

body{
    overflow-x: hidden;
    width: '100vw'
}

.icon{
    color: #333
}

.link{
    color: #666
}


.link:hover{
    color: #1890ff;
    filter: drop-shadow(0px 0px 5px rgb(0,118,220,0.3));
}
.icon:hover{
    filter: drop-shadow(0px 0px 5px rgb(0,118,220,0.3));
}

